let bgImage = require("./bgImage.jpg");
let logo = require("./logo.png");
let pdfHomeImage = require("./pdfCover.png");
let pdfFooter = require("./pdf_footer.png");
let pageFooter = require("./page_footer.png");
let pdfFooterLOGO = require("./pdf_footer_log.png");
let homeVideo = require("./bgImage.mp4");

let attribute_card_bg = require("./attribute_card_bg.jpg");

export const APP_IMAGE_BACKGOUND = bgImage;
export const APP_VIDEO_BACKGOUND = homeVideo;
export const APP_IMAGE_LOGO = logo;
export const APP_PDF_COVER_IMAGE = pdfHomeImage;

export const APP_PDF_FOOTER_LOGO = pdfFooterLOGO;
export const APP_PDF_FOOTER_IMAGE = pdfFooter;
export const PAGE_FOOTER_IMAGE = pageFooter;

export const ATTR_CARD_BG_IMG = attribute_card_bg;